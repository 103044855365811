import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorBoundary from "./ErrorBoundary";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import BackToTop from "./Components/BackToTop/BackToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Lazy loading components
const Home = lazy(() => import("./Components/Home/Home"));
const Intro = lazy(() => import("./Components/Intro/Intro"));
const Search = lazy(() => import("./Components/Search/Search"));
const Compare = lazy(() => import("./Components/Compare/Compare"));
const Plan = lazy(() => import("./Components/Plan/Plan"));
const BookNow = lazy(() => import("./Components/Plan/BookNow"));
const PayNow = lazy(() => import("./Components/Plan/PayNow"));
const Terms = lazy(() => import("./Components/Terms&Conditions/Terms"));
const Login = lazy(() => import("./Components/Includes/Login/Login"));
const ResetPassword = lazy(() => import("./Components/Includes/ResetPassword/ResetPassword"));
const NewDesign = lazy(() => import("./Components/Includes/Login/NewDesign"));
const ThankYou = lazy(() => import("./Components/Terms&Conditions/ThankYou"));
function App() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<div className="loader-line"></div>}>
        <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/intro" element={<Intro />} />
          <Route path="/search" element={<Search />} />
          <Route path="/compare" element={<Compare />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/register" element={<BookNow />} />
          <Route path="/pay-now" element={<PayNow />} />
          <Route path="/terms-&-conditions" element={<Terms />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/new-login" element={<NewDesign />} />
          <Route path="/thankyou-page" element={<ThankYou />} />
        </Routes>
      </Suspense>
      <BackToTop/>
    </ErrorBoundary>
  );
}

export default App;
