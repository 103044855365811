import React, { useEffect, useState } from 'react';import {
  faArrowUp
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import './BackToTop.css';

const BackToTop = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
  
    useEffect(() => {
      window.addEventListener("scroll", toggleVisibility);
      return () => {
        window.removeEventListener("scroll", toggleVisibility);
      };
    }, []);
  return (
    <div className="back-to-top">
    {visible && (
      <button onClick={scrollToTop} className="back-to-top-button">
       <FontAwesomeIcon icon={faArrowUp} />
      </button>
    )}
  </div>
  );
}

export default BackToTop;
