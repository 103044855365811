



import { createContext, useReducer } from "react";
import secureLocalStorage from "react-secure-storage";

export const NoteState = createContext();

const parseJSON = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};

const accessToken = localStorage.getItem("access-token");
const UId = secureLocalStorage.getItem("UId");
const role = secureLocalStorage.getItem("role");

const initialState = {
  accessToken: parseJSON(accessToken),
  UId: parseJSON(UId),
  role: parseJSON(role),
};

function reducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("access-token", JSON.stringify(action.accessToken));
      secureLocalStorage.setItem("UId", JSON.stringify(action.UId));
      secureLocalStorage.setItem("role", JSON.stringify(action.role));
      return {
        ...state,
        accessToken: action.accessToken,
        UId: action.UId,
        role: action.role,
      };
    case "LOGOUT":
      localStorage.removeItem("access-token"); 
      secureLocalStorage.removeItem("UId"); 
      secureLocalStorage.removeItem("role"); 
      return { ...state, accessToken: null, UId: null, role: null };
    default:
      return state;
  }
}

export function UserProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <NoteState.Provider value={value}>{props.children}</NoteState.Provider>
  );
}
